



















































import axios from "axios";
import { debounce } from "lodash";
import { defineComponent, reactive, watch } from "@vue/composition-api";
import { Diagnosis, DiagnosisFM } from "../models/diagnosis";
import { SaveContext } from "@/apps/core/models/formModel";
import useFormModel from "@/apps/core/modules/useFormModel";
import APP_CONFIG from "@/apps/core/modules/config";

export default defineComponent({
  name: "DiagnosisModalForm",
  components: {
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
  },
  props: { namaKomponen: String },
  setup(props, { emit }) {
    const form = new DiagnosisFM();
    const composition = useFormModel(form);
    const diagnosis = composition.instance as Diagnosis;
    const diagList = reactive([] as Array<Diagnosis>);

    const fetchOptions = async (inputStr: string) => {
      diagList.splice(0, diagList.length);
      if (inputStr.length < 3) {
        return;
      }

      const params: Record<string, string> = {
        sf: "code,name",
        df: "code,name",
        terms: inputStr,
      };
      const response = await axios.get(APP_CONFIG.diagnosisIcd10, { params: params });
      const data = response.data[3].splice(0, 20);
      for (const row of data) {
        const diagnosis = new Diagnosis();
        diagnosis.kode = row[0];
        diagnosis.nama = row[1];
        diagList.push(diagnosis);
      }
    };

    const fetchOptionsTyping = debounce(fetchOptions, 300);

    const onSelect = (diag?: Diagnosis) => {
      diagnosis.nama = diag?.nama ?? "";
      diagnosis.kode = diag?.kode ?? "";
      composition.validate("nama");
    };

    watch(
      () => props.namaKomponen,
      (newValue) => {
        if (newValue) {
          diagnosis.nama = newValue;
          composition.validate("nama");
        }
      }
    );

    const saveForm = async () => {
      await composition.formRef.save(SaveContext.Create, false);
      if (diagnosis.id) {
        const obj = { id: diagnosis.id, nama: diagnosis.nama };
        emit("close", obj);
      }
    };

    return {
      // Data
      diagList,

      // Composition
      ...composition,

      // Method
      fetchOptionsTyping,
      onSelect,
      saveForm,
    };
  },
});
